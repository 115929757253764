var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"simpleRules",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-card',{attrs:{"no-body":""},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h4',{staticClass:"mr-auto mb-0"},[_vm._v(" XXX ")]),(!_vm.editMode)?_c('b-button',{attrs:{"variant":"primary","size":"sm"},on:{"click":function($event){_vm.editMode = true}}},[_vm._v(" Edit ")]):_vm._e()]},proxy:true},(_vm.editMode)?{key:"footer",fn:function(){return [_c('b-button',{attrs:{"type":"submit","variant":"primary"},on:{"click":function($event){return handleSubmit(_vm.onSubmit)}}},[_vm._v(" Submit ")]),_c('b-button',{staticClass:"ml-1",attrs:{"type":"reset","variant":"secondary"},on:{"click":_vm.cancelForm}},[_vm._v(" Cancel ")])]},proxy:true}:null],null,true)},[_c('div',[_c('div',{staticClass:"p-2 border-bottom"},[_c('b-row',[_c('b-col',{attrs:{"cols":"3"}},[_c('h6',{staticClass:"m-0"},[_c('strong',[_vm._v("Configurations")])])]),_c('b-col',[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-row',[_c('b-col',[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Application Name","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v("Application Name ")]),_c('b-form-input',{attrs:{"plaintext":!_vm.editMode,"size":"sm","state":errors.length > 0 ? false:null,"placeholder":"Application Name"},model:{value:(_vm.actualForm.application_name),callback:function ($$v) {_vm.$set(_vm.actualForm, "application_name", $$v)},expression:"actualForm.application_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Main Site","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v("Main Site")]),_c('b-form-input',{attrs:{"plaintext":!_vm.editMode,"size":"sm","state":errors.length > 0 ? false:null,"placeholder":"Main Site"},model:{value:(_vm.actualForm.main_site),callback:function ($$v) {_vm.$set(_vm.actualForm, "main_site", $$v)},expression:"actualForm.main_site"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Mailer","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v("Mailer ")]),_c('b-form-input',{attrs:{"plaintext":!_vm.editMode,"size":"sm","state":errors.length > 0 ? false:null,"placeholder":"Mailer"},model:{value:(_vm.actualForm.mailer),callback:function ($$v) {_vm.$set(_vm.actualForm, "mailer", $$v)},expression:"actualForm.mailer"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Site Title","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v("Site Title")]),_c('b-form-input',{attrs:{"plaintext":!_vm.editMode,"size":"sm","state":errors.length > 0 ? false:null,"placeholder":"Site Title"},model:{value:(_vm.actualForm.site_title),callback:function ($$v) {_vm.$set(_vm.actualForm, "site_title", $$v)},expression:"actualForm.site_title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Platform Version ","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v("Platform Version ")]),_c('b-form-input',{attrs:{"plaintext":!_vm.editMode,"size":"sm","state":errors.length > 0 ? false:null,"placeholder":"Platform Version"},model:{value:(_vm.actualForm.platform_version),callback:function ($$v) {_vm.$set(_vm.actualForm, "platform_version", $$v)},expression:"actualForm.platform_version"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1)],1)],1)],1)],1)],1),_c('div',{staticClass:"p-2 border-bottom"},[_c('b-row',[_c('b-col',{attrs:{"cols":"3"}},[_c('h6',{staticClass:"m-0"},[_c('strong',[_vm._v("Logos")])])]),_c('b-col',[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-row',[_c('b-col',[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Logo","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v("Logo ")]),_c('b-row',[_c('b-col',[[_c('div',{staticClass:"pt-1 px-2 bg-light mb-1"},[(_vm.fileArray.length < 1 && !Array.isArray(_vm.fileArray))?_c('b-row',[_c('b-col',[_c('p',{staticClass:"mb-1 py-3 text-center"},[_vm._v(" No Files Yet ")])])],1):_vm._e(),_c('b-row',[(Array.isArray(_vm.fileArray))?_c('b-img',{staticClass:"img-fluid mb-1",attrs:{"src":_vm.processBase64String(_vm.actualForm.logo)}}):_vm._e(),(
                                        _vm.fileArrayFileType[0] === 'jpeg' || _vm.fileArrayFileType[0] === 'png' )?[(!Array.isArray(_vm.fileArray))?_c('b-img',{staticClass:"img-fluid mb-1",attrs:{"src":_vm.processBase64String(_vm.actualForm.logo)}}):_vm._e(),(_vm.debugMode)?_c('div',{staticClass:"d-none"},[_c('b-button-group',{attrs:{"size":"sm"}},[_c('b-button',{on:{"click":function($event){return _vm.viewBase64asImage(_vm.fileArray[0])}}},[_vm._v(" V ")]),_c('b-button',{on:{"click":function($event){return _vm.downloadBase64asImage(_vm.fileArray[0], 'filename' , _vm.fileArrayFileType[0])}}},[_vm._v(" D ")])],1)],1):_vm._e()]:_vm._e()],2)],1)]],2)],1),(_vm.editMode)?_c('b-form-file',{attrs:{"accept":".jpg, .png","placeholder":"Choose a file or drop it here...","drop-placeholder":"Drop file here..."},on:{"input":function($event){return _vm.onFileSelect('logo')}},model:{value:(_vm.fileInputMulti),callback:function ($$v) {_vm.fileInputMulti=$$v},expression:"fileInputMulti"}}):_vm._e(),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Alt Logo","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                        var errors = ref.errors;
return [_c('label',[_vm._v("Alt Logo ")]),_c('b-row',[_c('b-col',[[_c('div',{staticClass:"pt-1 px-2 bg-light mb-1"},[(_vm.fileArray2.length < 1 && !Array.isArray(_vm.fileArray2))?_c('b-row',[_c('b-col',[_c('p',{staticClass:"mb-1 py-3 text-center"},[_vm._v(" No Files Yet ")])])],1):_vm._e(),_c('b-row',[(Array.isArray(_vm.fileArray2))?_c('b-img',{staticClass:"img-fluid mb-1",attrs:{"src":_vm.processBase64String(_vm.actualForm.logo_alt)}}):_vm._e(),(
                                        _vm.fileArrayFileType2[0] === 'jpeg' || _vm.fileArrayFileType2[0] === 'png' )?[_c('b-img',{staticClass:"img-fluid mb-1",attrs:{"src":_vm.processBase64String(_vm.actualForm.logo_alt)}}),(_vm.debugMode)?_c('div',{staticClass:"d-none"},[_c('b-button-group',{attrs:{"size":"sm"}},[_c('b-button',{on:{"click":function($event){return _vm.viewBase64asImage(_vm.fileArray2[0])}}},[_vm._v(" V ")]),_c('b-button',{on:{"click":function($event){return _vm.downloadBase64asImage(_vm.fileArray2[0], 'filename' , _vm.fileArrayFileType2[0])}}},[_vm._v(" D ")])],1)],1):_vm._e()]:_vm._e()],2)],1)]],2)],1),(_vm.editMode)?_c('b-form-file',{attrs:{"accept":".jpg, .png","placeholder":"Choose a file or drop it here...","drop-placeholder":"Drop file here..."},on:{"input":function($event){return _vm.onFileSelect2('logo_alt')}},model:{value:(_vm.fileInputMulti2),callback:function ($$v) {_vm.fileInputMulti2=$$v},expression:"fileInputMulti2"}}):_vm._e(),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"QR Code Logo","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                        var errors = ref.errors;
return [_c('label',[_vm._v("QR Code Logo ")]),_c('b-row',[_c('b-col',[[_c('div',{staticClass:"pt-1 px-2 bg-light mb-1"},[(_vm.fileArray3.length < 1 && !Array.isArray(_vm.fileArray3))?_c('b-row',[_c('b-col',[_c('p',{staticClass:"mb-1 py-3 text-center"},[_vm._v(" No Files Yet ")])])],1):_vm._e(),_c('b-row',[(Array.isArray(_vm.fileArray))?_c('b-img',{staticClass:"img-fluid mb-1",attrs:{"src":_vm.processBase64String(_vm.actualForm.qr_code_logo)}}):_vm._e(),(
                                        _vm.fileArrayFileType3[0] === 'jpeg' || _vm.fileArrayFileType3[0] === 'png' )?[_c('b-img',{staticClass:"img-fluid mb-1",attrs:{"src":_vm.processBase64String(_vm.actualForm.qr_code_logo)}}),(_vm.debugMode)?_c('div',{staticClass:"d-none"},[_c('b-button-group',{attrs:{"size":"sm"}},[_c('b-button',{on:{"click":function($event){return _vm.viewBase64asImage(_vm.fileArray3[0])}}},[_vm._v(" V ")]),_c('b-button',{on:{"click":function($event){return _vm.downloadBase64asImage(_vm.fileArray3[0], 'filename' , _vm.fileArrayFileType3[0])}}},[_vm._v(" D ")])],1)],1):_vm._e()]:_vm._e()],2)],1)]],2)],1),(_vm.editMode)?_c('b-form-file',{attrs:{"accept":".jpg, .png","placeholder":"Choose a file or drop it here...","drop-placeholder":"Drop file here..."},on:{"input":function($event){return _vm.onFileSelect3('qr_code_logo')}},model:{value:(_vm.fileInputMulti3),callback:function ($$v) {_vm.fileInputMulti3=$$v},expression:"fileInputMulti3"}}):_vm._e(),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1)],1)],1)],1)],1)],1),_c('div',{staticClass:"p-2 border-bottom"},[_c('b-row',[_c('b-col',{attrs:{"cols":"3"}},[_c('h6',{staticClass:"m-0"},[_c('strong',[_vm._v("Meta Data")])])]),_c('b-col',[_c('b-row',[_c('b-col',{attrs:{"cols":"8"}},[_c('b-row',[_c('b-col',[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Favicon","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                        var errors = ref.errors;
return [_c('label',[_vm._v("Favicon")]),_c('b-row',[_c('b-col',[[_c('div',{staticClass:"pt-1 px-2 bg-light mb-1"},[(_vm.fileArray4.length < 1 && !Array.isArray(_vm.fileArray4))?_c('b-row',[_c('b-col',[_c('p',{staticClass:"mb-1 py-3 text-center"},[_vm._v(" No Files Yet ")])])],1):_vm._e(),_c('b-row',[(Array.isArray(_vm.fileArray4))?_c('b-img',{staticClass:"img-fluid mb-1",attrs:{"src":_vm.processBase64String(_vm.actualForm.favicon)}}):_vm._e(),(
                                        _vm.fileArrayFileType4[0] === 'jpeg' || _vm.fileArrayFileType4[0] === 'png' )?[_c('b-img',{staticClass:"img-fluid mb-1",attrs:{"src":_vm.processBase64String(_vm.actualForm.favicon)}}),(_vm.debugMode)?_c('div',{staticClass:"d-none"},[_c('b-button-group',{attrs:{"size":"sm"}},[_c('b-button',{on:{"click":function($event){return _vm.viewBase64asImage(_vm.fileArray4[0])}}},[_vm._v(" V ")]),_c('b-button',{on:{"click":function($event){return _vm.downloadBase64asImage(_vm.fileArray4[0], 'filename' , _vm.fileArrayFileType4[0])}}},[_vm._v(" D ")])],1)],1):_vm._e()]:_vm._e()],2)],1)]],2)],1),(_vm.editMode)?_c('b-form-file',{attrs:{"accept":".jpg, .png","placeholder":"Choose a file or drop it here...","drop-placeholder":"Drop file here..."},on:{"input":function($event){return _vm.onFileSelect4('favicon')}},model:{value:(_vm.fileInputMulti4),callback:function ($$v) {_vm.fileInputMulti4=$$v},expression:"fileInputMulti4"}}):_vm._e(),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Meta Image","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                        var errors = ref.errors;
return [_c('label',[_vm._v("Meta Image")]),_c('b-row',[_c('b-col',[[_c('div',{staticClass:"pt-1 px-2 bg-light mb-1"},[(_vm.fileArray5.length < 1 && !Array.isArray(_vm.fileArray5))?_c('b-row',[_c('b-col',[_c('p',{staticClass:"mb-1 py-3 text-center"},[_vm._v(" No Files Yet ")])])],1):_vm._e(),_c('b-row',[(Array.isArray(_vm.fileArray5))?_c('b-img',{staticClass:"img-fluid mb-1",attrs:{"src":_vm.processBase64String(_vm.actualForm.meta_image)}}):_vm._e(),(
                                        _vm.fileArrayFileType5[0] === 'jpeg' || _vm.fileArrayFileType5[0] === 'png' )?[_c('b-img',{staticClass:"img-fluid mb-1",attrs:{"src":_vm.processBase64String(_vm.actualForm.meta_image)}}),(_vm.debugMode)?_c('div',{staticClass:"d-none"},[_c('b-button-group',{attrs:{"size":"sm"}},[_c('b-button',{on:{"click":function($event){return _vm.viewBase64asImage(_vm.fileArray5[0])}}},[_vm._v(" V ")]),_c('b-button',{on:{"click":function($event){return _vm.downloadBase64asImage(_vm.fileArray5[0], 'filename' , _vm.fileArrayFileType5[0])}}},[_vm._v(" D ")])],1)],1):_vm._e()]:_vm._e()],2)],1)]],2)],1),(_vm.editMode)?_c('b-form-file',{attrs:{"accept":".jpg, .png","placeholder":"Choose a file or drop it here...","drop-placeholder":"Drop file here..."},on:{"input":function($event){return _vm.onFileSelect5('meta_image')}},model:{value:(_vm.fileInputMulti5),callback:function ($$v) {_vm.fileInputMulti5=$$v},expression:"fileInputMulti5"}}):_vm._e(),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Meta Description","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                        var errors = ref.errors;
return [_c('label',[_vm._v("Meta Description ")]),_c('b-form-input',{attrs:{"plaintext":!_vm.editMode,"size":"sm","state":errors.length > 0 ? false:null,"placeholder":"Meta Description"},model:{value:(_vm.actualForm.meta_description),callback:function ($$v) {_vm.$set(_vm.actualForm, "meta_description", $$v)},expression:"actualForm.meta_description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1)],1),_c('b-col')],1)],1)],1)],1),_c('div',{staticClass:"p-2 border-bottom"},[_c('b-row',[_c('b-col',{attrs:{"cols":"3"}},[_c('h6',{staticClass:"m-0"},[_c('strong',[_vm._v("Theme")])])]),_c('b-col',[_c('b-row',[_c('b-col',{attrs:{"cols":"8"}},[_c('b-row',[_c('b-col',[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Primary Color","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                        var errors = ref.errors;
return [_c('label',[_vm._v("Primary Color ")]),_c('b-form-input',{attrs:{"plaintext":!_vm.editMode,"size":"sm","state":errors.length > 0 ? false:null,"placeholder":"Primary color"},model:{value:(_vm.actualForm.primary_color),callback:function ($$v) {_vm.$set(_vm.actualForm, "primary_color", $$v)},expression:"actualForm.primary_color"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Secondary Color","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                        var errors = ref.errors;
return [_c('label',[_vm._v("Secondary Color ")]),_c('b-form-input',{attrs:{"plaintext":!_vm.editMode,"size":"sm","state":errors.length > 0 ? false:null,"placeholder":"Secondary Color"},model:{value:(_vm.actualForm.secondary_color),callback:function ($$v) {_vm.$set(_vm.actualForm, "secondary_color", $$v)},expression:"actualForm.secondary_color"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Background Image","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                        var errors = ref.errors;
return [_c('label',[_vm._v("Background Image")]),_c('b-row',[_c('b-col',[[_c('div',{staticClass:"pt-1 px-2 bg-light mb-1"},[(_vm.fileArray6.length < 1 && !Array.isArray(_vm.fileArray6))?_c('b-row',[_c('b-col',[_c('p',{staticClass:"mb-1 py-3 text-center"},[_vm._v(" No Files Yet ")])])],1):_vm._e(),_c('b-row',[(Array.isArray(_vm.fileArray6))?_c('b-img',{staticClass:"img-fluid mb-1",attrs:{"src":_vm.processBase64String(_vm.actualForm.background_image)}}):_vm._e(),(
                                        _vm.fileArrayFileType6[0] === 'jpeg' || _vm.fileArrayFileType6[0] === 'png' )?[_c('b-img',{staticClass:"img-fluid mb-1",attrs:{"src":_vm.processBase64String(_vm.actualForm.background_image)}}),(_vm.debugMode)?_c('div',{staticClass:"d-none"},[_c('b-button-group',{attrs:{"size":"sm"}},[_c('b-button',{on:{"click":function($event){return _vm.viewBase64asImage(_vm.fileArray6[0])}}},[_vm._v(" V ")]),_c('b-button',{on:{"click":function($event){return _vm.downloadBase64asImage(_vm.fileArray6[0], 'filename' , _vm.fileArrayFileType6[0])}}},[_vm._v(" D ")])],1)],1):_vm._e()]:_vm._e()],2)],1)]],2)],1),(_vm.editMode)?_c('b-form-file',{attrs:{"accept":".jpg, .png","placeholder":"Choose a file or drop it here...","drop-placeholder":"Drop file here..."},on:{"input":function($event){return _vm.onFileSelect6('background_image')}},model:{value:(_vm.fileInputMulti6),callback:function ($$v) {_vm.fileInputMulti6=$$v},expression:"fileInputMulti6"}}):_vm._e(),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Background Color","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                        var errors = ref.errors;
return [_c('label',[_vm._v("Background Color ")]),_c('b-form-input',{attrs:{"plaintext":!_vm.editMode,"size":"sm","state":errors.length > 0 ? false:null,"placeholder":"Background color"},model:{value:(_vm.actualForm.background_color),callback:function ($$v) {_vm.$set(_vm.actualForm, "background_color", $$v)},expression:"actualForm.background_color"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1)],1),_c('b-col')],1)],1)],1)],1),_c('div',{staticClass:"p-2 border-bottom"},[_c('b-row',[_c('b-col',{attrs:{"cols":"3"}},[_c('h6',{staticClass:"m-0"},[_c('strong',[_vm._v("URLs")])])]),_c('b-col',[_c('b-row',[_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Contact URL","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                        var errors = ref.errors;
return [_c('label',[_vm._v("Contact URL ")]),_c('b-form-input',{attrs:{"plaintext":!_vm.editMode,"size":"sm","state":errors.length > 0 ? false:null,"placeholder":"Contact URL"},model:{value:(_vm.actualForm.contact_url),callback:function ($$v) {_vm.$set(_vm.actualForm, "contact_url", $$v)},expression:"actualForm.contact_url"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"FAQ URL ","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                        var errors = ref.errors;
return [_c('label',[_vm._v("FAQ URL ")]),_c('b-form-input',{attrs:{"plaintext":!_vm.editMode,"size":"sm","state":errors.length > 0 ? false:null,"placeholder":"FAQ URL"},model:{value:(_vm.actualForm.faq_url),callback:function ($$v) {_vm.$set(_vm.actualForm, "faq_url", $$v)},expression:"actualForm.faq_url"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Contact URL","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                        var errors = ref.errors;
return [_c('label',[_vm._v("Contact URL ")]),_c('b-form-input',{attrs:{"plaintext":!_vm.editMode,"size":"sm","state":errors.length > 0 ? false:null,"placeholder":"Contact URL"},model:{value:(_vm.actualForm.contact_url),callback:function ($$v) {_vm.$set(_vm.actualForm, "contact_url", $$v)},expression:"actualForm.contact_url"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Site Support","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                        var errors = ref.errors;
return [_c('label',[_vm._v("Site Support ")]),_c('b-form-input',{attrs:{"plaintext":!_vm.editMode,"size":"sm","state":errors.length > 0 ? false:null,"placeholder":"Site Support"},model:{value:(_vm.actualForm.site_support),callback:function ($$v) {_vm.$set(_vm.actualForm, "site_support", $$v)},expression:"actualForm.site_support"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Privacy Term URL","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                        var errors = ref.errors;
return [_c('label',[_vm._v("Privacy Term URL")]),_c('b-form-input',{attrs:{"plaintext":!_vm.editMode,"size":"sm","state":errors.length > 0 ? false:null,"placeholder":"Privacy Term URL"},model:{value:(_vm.actualForm.privacy_term_url),callback:function ($$v) {_vm.$set(_vm.actualForm, "privacy_term_url", $$v)},expression:"actualForm.privacy_term_url"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col')],1)],1)],1)],1),_c('div',{staticClass:"p-2 border-bottom"},[_c('b-row',[_c('b-col',{attrs:{"cols":"3"}},[_c('h6',{staticClass:"m-0"},[_c('strong',[_vm._v("Social")])])]),_c('b-col',[_c('b-row',[_c('b-col',{attrs:{"cols":"8"}},[_c('b-row',[_c('b-col',[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Facebook","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                        var errors = ref.errors;
return [_c('label',[_vm._v("Facebook ")]),_c('b-form-input',{attrs:{"plaintext":!_vm.editMode,"size":"sm","state":errors.length > 0 ? false:null,"placeholder":"Facebook"},model:{value:(_vm.actualForm.facebook),callback:function ($$v) {_vm.$set(_vm.actualForm, "facebook", $$v)},expression:"actualForm.facebook"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"instagram","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                        var errors = ref.errors;
return [_c('label',[_vm._v("Instagram ")]),_c('b-form-input',{attrs:{"plaintext":!_vm.editMode,"size":"sm","state":errors.length > 0 ? false:null,"placeholder":"Instagram"},model:{value:(_vm.actualForm.instagram),callback:function ($$v) {_vm.$set(_vm.actualForm, "instagram", $$v)},expression:"actualForm.instagram"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"linkedin","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                        var errors = ref.errors;
return [_c('label',[_vm._v("Linkedin ")]),_c('b-form-input',{attrs:{"plaintext":!_vm.editMode,"size":"sm","state":errors.length > 0 ? false:null,"placeholder":"LinkedIn"},model:{value:(_vm.actualForm.linkedin),callback:function ($$v) {_vm.$set(_vm.actualForm, "linkedin", $$v)},expression:"actualForm.linkedin"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Twitter","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                        var errors = ref.errors;
return [_c('label',[_vm._v("Twitter ")]),_c('b-form-input',{attrs:{"plaintext":!_vm.editMode,"size":"sm","state":errors.length > 0 ? false:null,"placeholder":"Twitter"},model:{value:(_vm.actualForm.twitter),callback:function ($$v) {_vm.$set(_vm.actualForm, "twitter", $$v)},expression:"actualForm.twitter"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1)],1),_c('b-col')],1)],1)],1)],1),_c('div',{staticClass:"p-2 border-bottom"},[_c('b-row',[_c('b-col',{attrs:{"cols":"3"}},[_c('h6',{staticClass:"m-0"},[_c('strong',[_vm._v("API settings")])])]),_c('b-col',[_c('b-row',[_c('b-col',{attrs:{"cols":"8"}},[_c('b-row',[_c('b-col',[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Base URL Endpoint","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                        var errors = ref.errors;
return [_c('label',[_vm._v("Base URL Endpoint ")]),_c('b-form-input',{attrs:{"plaintext":!_vm.editMode,"size":"sm","state":errors.length > 0 ? false:null,"placeholder":"Base URL Endpoint"},model:{value:(_vm.actualForm.base_url_endpoint),callback:function ($$v) {_vm.$set(_vm.actualForm, "base_url_endpoint", $$v)},expression:"actualForm.base_url_endpoint"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Callback URL","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                        var errors = ref.errors;
return [_c('label',[_vm._v("Callback URL ")]),_c('b-form-input',{attrs:{"plaintext":!_vm.editMode,"size":"sm","state":errors.length > 0 ? false:null,"placeholder":"Callback URL"},model:{value:(_vm.actualForm.callback_url),callback:function ($$v) {_vm.$set(_vm.actualForm, "callback_url", $$v)},expression:"actualForm.callback_url"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1)],1),_c('b-col')],1)],1)],1)],1),_c('div',{staticClass:"p-2 border-bottom"},[_c('b-row',[_c('b-col',{attrs:{"cols":"3"}},[_c('h6',{staticClass:"m-0"},[_c('strong',[_vm._v("Footer")])])]),_c('b-col',[_c('b-row',[_c('b-col',{attrs:{"cols":"8"}},[_c('b-row',[_c('b-col',[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Powered By","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                        var errors = ref.errors;
return [_c('label',[_vm._v("Powered By ")]),_c('b-form-input',{attrs:{"plaintext":!_vm.editMode,"size":"sm","state":errors.length > 0 ? false:null,"placeholder":"Powered By"},model:{value:(_vm.actualForm.powered_bye),callback:function ($$v) {_vm.$set(_vm.actualForm, "powered_bye", $$v)},expression:"actualForm.powered_bye"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1)],1),_c('b-col')],1)],1)],1)],1)])])]}}])}),(_vm.debugMode)?_c('pre',[_vm._v(_vm._s(_vm.actualForm))]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }