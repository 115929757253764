<template>
  <div>
    <validation-observer
      ref="simpleRules"
      v-slot="{ handleSubmit }"
    >
      <b-card
        no-body
      >
        <template #header>
          <h4 class="mr-auto mb-0">
            XXX
          </h4>
          <b-button
            v-if="!editMode"
            variant="primary"
            size="sm"
            @click="editMode = true"
          >
            Edit
          </b-button>
        </template>
        <template
          v-if="editMode"
          #footer
        >
          <b-button
            type="submit"
            variant="primary"
            @click="handleSubmit(onSubmit)"
          >
            Submit
          </b-button>
          <b-button
            class="ml-1"
            type="reset"
            variant="secondary"
            @click="cancelForm"
          >
            Cancel
          </b-button>
        </template>

        <div>
          <!-- group Configurations-->
          <div class="p-2 border-bottom">
            <b-row>
              <b-col cols="3">
                <h6 class="m-0">
                  <strong>Configurations</strong>
                </h6>
              </b-col>
              <b-col>
                <b-row>
                  <b-col
                    cols="12"
                  >
                    <b-row>
                      <b-col>
                        <b-form-group>
                          <validation-provider
                            #default="{ errors }"
                            name="Application Name"
                            rules=""
                          >
                            <label>Application Name </label>
                            <b-form-input
                              v-model="actualForm.application_name"
                              :plaintext="!editMode"
                              size="sm"
                              :state="errors.length > 0 ? false:null"
                              placeholder="Application Name"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                        <b-form-group>
                          <validation-provider
                            #default="{ errors }"
                            name="Main Site"
                            rules=""
                          >
                            <label>Main Site</label>
                            <b-form-input
                              v-model="actualForm.main_site"
                              :plaintext="!editMode"
                              size="sm"
                              :state="errors.length > 0 ? false:null"
                              placeholder="Main Site"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                        <b-form-group>
                          <validation-provider
                            #default="{ errors }"
                            name="Mailer"
                            rules=""
                          >
                            <label>Mailer </label>
                            <b-form-input
                              v-model="actualForm.mailer"
                              :plaintext="!editMode"
                              size="sm"
                              :state="errors.length > 0 ? false:null"
                              placeholder="Mailer"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col>
                        <b-form-group>
                          <validation-provider
                            #default="{ errors }"
                            name="Site Title"
                            rules=""
                          >
                            <label>Site Title</label>
                            <b-form-input
                              v-model="actualForm.site_title"
                              :plaintext="!editMode"
                              size="sm"
                              :state="errors.length > 0 ? false:null"
                              placeholder="Site Title"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                        <b-form-group>
                          <validation-provider
                            #default="{ errors }"
                            name="Platform Version "
                            rules=""
                          >
                            <label>Platform Version </label>
                            <b-form-input
                              v-model="actualForm.platform_version"
                              :plaintext="!editMode"
                              size="sm"
                              :state="errors.length > 0 ? false:null"
                              placeholder="Platform Version"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </div>
          <!-- end group Configurations-->
          <!-- group logo-->
          <div class="p-2 border-bottom">
            <b-row>
              <b-col cols="3">
                <h6 class="m-0">
                  <strong>Logos</strong>
                </h6>
              </b-col>
              <b-col>
                <b-row>
                  <b-col
                    cols="12"
                  >
                    <b-row>
                      <b-col>
                        <b-form-group>
                          <validation-provider
                            #default="{ errors }"
                            name="Logo"
                            rules=""
                          >
                            <label>Logo </label>
                            <b-row>
                              <b-col>
                                <template>
                                  <div
                                    class="pt-1 px-2 bg-light mb-1"
                                  >
                                    <b-row v-if="fileArray.length < 1 && !Array.isArray(fileArray)">
                                      <b-col>
                                        <p class="mb-1 py-3 text-center">
                                          No Files Yet
                                        </p>
                                      </b-col>
                                    </b-row>
                                    <b-row>
                                      <b-img
                                        v-if="Array.isArray(fileArray)"
                                        class="img-fluid mb-1"
                                        :src="processBase64String(actualForm.logo)"
                                      />
                                      <template
                                        v-if="
                                          fileArrayFileType[0] === 'jpeg' || fileArrayFileType[0] === 'png' "
                                      >
                                        <b-img
                                          v-if="!Array.isArray(fileArray)"
                                          class="img-fluid mb-1"
                                          :src="processBase64String(actualForm.logo)"
                                        />
                                        <div
                                          v-if="debugMode"
                                          class="d-none"
                                        >
                                          <b-button-group size="sm">
                                            <b-button @click="viewBase64asImage(fileArray[0])">
                                              V
                                            </b-button>
                                            <b-button @click="downloadBase64asImage(fileArray[0], 'filename' , fileArrayFileType[0])">
                                              D
                                            </b-button>
                                          </b-button-group>
                                        </div>
                                      </template>
                                    </b-row>
                                  </div>
                                </template>
                              </b-col>
                            </b-row>
                            <b-form-file
                              v-if="editMode"
                              v-model="fileInputMulti"
                              accept=".jpg, .png"
                              placeholder="Choose a file or drop it here..."
                              drop-placeholder="Drop file here..."
                              @input="onFileSelect('logo')"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>

                      </b-col>
                      <b-col>
                        <b-form-group>
                          <validation-provider
                            #default="{ errors }"
                            name="Alt Logo"
                            rules=""
                          >
                            <label>Alt Logo </label>
                            <b-row>
                              <b-col>
                                <template>
                                  <div
                                    class="pt-1 px-2 bg-light mb-1"
                                  >
                                    <b-row v-if="fileArray2.length < 1 && !Array.isArray(fileArray2)">
                                      <b-col>
                                        <p class="mb-1 py-3 text-center">
                                          No Files Yet
                                        </p>
                                      </b-col>
                                    </b-row>
                                    <b-row>
                                      <b-img
                                        v-if="Array.isArray(fileArray2)"
                                        class="img-fluid mb-1"
                                        :src="processBase64String(actualForm.logo_alt)"
                                      />
                                      <template
                                        v-if="
                                          fileArrayFileType2[0] === 'jpeg' || fileArrayFileType2[0] === 'png' "
                                      >
                                        <b-img
                                          class="img-fluid mb-1"
                                          :src="processBase64String(actualForm.logo_alt)"
                                        />
                                        <div
                                          v-if="debugMode"
                                          class="d-none"
                                        >
                                          <b-button-group size="sm">
                                            <b-button @click="viewBase64asImage(fileArray2[0])">
                                              V
                                            </b-button>
                                            <b-button @click="downloadBase64asImage(fileArray2[0], 'filename' , fileArrayFileType2[0])">
                                              D
                                            </b-button>
                                          </b-button-group>
                                        </div>
                                      </template>
                                    </b-row>
                                  </div>
                                </template>
                              </b-col>
                            </b-row>
                            <b-form-file
                              v-if="editMode"
                              v-model="fileInputMulti2"
                              accept=".jpg, .png"
                              placeholder="Choose a file or drop it here..."
                              drop-placeholder="Drop file here..."
                              @input="onFileSelect2('logo_alt')"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col>
                        <b-form-group>
                          <validation-provider
                            #default="{ errors }"
                            name="QR Code Logo"
                            rules=""
                          >
                            <label>QR Code Logo </label>
                            <b-row>
                              <b-col>
                                <template>
                                  <div
                                    class="pt-1 px-2 bg-light mb-1"
                                  >
                                    <b-row v-if="fileArray3.length < 1 && !Array.isArray(fileArray3)">
                                      <b-col>
                                        <p class="mb-1 py-3 text-center">
                                          No Files Yet
                                        </p>
                                      </b-col>
                                    </b-row>
                                    <b-row>
                                      <b-img
                                        v-if="Array.isArray(fileArray)"
                                        class="img-fluid mb-1"
                                        :src="processBase64String(actualForm.qr_code_logo)"
                                      />
                                      <template
                                        v-if="
                                          fileArrayFileType3[0] === 'jpeg' || fileArrayFileType3[0] === 'png' "
                                      >
                                        <b-img
                                          class="img-fluid mb-1"
                                          :src="processBase64String(actualForm.qr_code_logo)"
                                        />
                                        <div
                                          v-if="debugMode"
                                          class="d-none"
                                        >
                                          <b-button-group size="sm">
                                            <b-button @click="viewBase64asImage(fileArray3[0])">
                                              V
                                            </b-button>
                                            <b-button @click="downloadBase64asImage(fileArray3[0], 'filename' , fileArrayFileType3[0])">
                                              D
                                            </b-button>
                                          </b-button-group>
                                        </div>
                                      </template>
                                    </b-row>
                                  </div>
                                </template>
                              </b-col>
                            </b-row>
                            <b-form-file
                              v-if="editMode"
                              v-model="fileInputMulti3"
                              accept=".jpg, .png"
                              placeholder="Choose a file or drop it here..."
                              drop-placeholder="Drop file here..."
                              @input="onFileSelect3('qr_code_logo')"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </div>
          <!-- end group logo-->

          <!-- group meta data-->
          <div class="p-2 border-bottom">
            <b-row>
              <b-col cols="3">
                <h6 class="m-0">
                  <strong>Meta Data</strong>
                </h6>
              </b-col>
              <b-col>
                <b-row>
                  <b-col cols="8">
                    <b-row>
                      <b-col>
                        <b-form-group>
                          <validation-provider
                            #default="{ errors }"
                            name="Favicon"
                            rules=""
                          >
                            <label>Favicon</label>
                            <b-row>
                              <b-col>
                                <template>
                                  <div
                                    class="pt-1 px-2 bg-light mb-1"
                                  >
                                    <b-row v-if="fileArray4.length < 1 && !Array.isArray(fileArray4)">
                                      <b-col>
                                        <p class="mb-1 py-3 text-center">
                                          No Files Yet
                                        </p>
                                      </b-col>
                                    </b-row>
                                    <b-row>
                                      <b-img
                                        v-if="Array.isArray(fileArray4)"
                                        class="img-fluid mb-1"
                                        :src="processBase64String(actualForm.favicon)"
                                      />
                                      <template
                                        v-if="
                                          fileArrayFileType4[0] === 'jpeg' || fileArrayFileType4[0] === 'png' "
                                      >
                                        <b-img
                                          class="img-fluid mb-1"
                                          :src="processBase64String(actualForm.favicon)"
                                        />
                                        <div
                                          v-if="debugMode"
                                          class="d-none"
                                        >
                                          <b-button-group size="sm">
                                            <b-button @click="viewBase64asImage(fileArray4[0])">
                                              V
                                            </b-button>
                                            <b-button @click="downloadBase64asImage(fileArray4[0], 'filename' , fileArrayFileType4[0])">
                                              D
                                            </b-button>
                                          </b-button-group>
                                        </div>
                                      </template>
                                    </b-row>
                                  </div>
                                </template>
                              </b-col>
                            </b-row>
                            <b-form-file
                              v-if="editMode"
                              v-model="fileInputMulti4"
                              accept=".jpg, .png"
                              placeholder="Choose a file or drop it here..."
                              drop-placeholder="Drop file here..."
                              @input="onFileSelect4('favicon')"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col>
                        <b-form-group>
                          <validation-provider
                            #default="{ errors }"
                            name="Meta Image"
                            rules=""
                          >
                            <label>Meta Image</label>
                            <b-row>
                              <b-col>
                                <template>
                                  <div
                                    class="pt-1 px-2 bg-light mb-1"
                                  >
                                    <b-row v-if="fileArray5.length < 1 && !Array.isArray(fileArray5)">
                                      <b-col>
                                        <p class="mb-1 py-3 text-center">
                                          No Files Yet
                                        </p>
                                      </b-col>
                                    </b-row>
                                    <b-row>
                                      <b-img
                                        v-if="Array.isArray(fileArray5)"
                                        class="img-fluid mb-1"
                                        :src="processBase64String(actualForm.meta_image)"
                                      />
                                      <template
                                        v-if="
                                          fileArrayFileType5[0] === 'jpeg' || fileArrayFileType5[0] === 'png' "
                                      >
                                        <b-img
                                          class="img-fluid mb-1"
                                          :src="processBase64String(actualForm.meta_image)"
                                        />
                                        <div
                                          v-if="debugMode"
                                          class="d-none"
                                        >
                                          <b-button-group size="sm">
                                            <b-button @click="viewBase64asImage(fileArray5[0])">
                                              V
                                            </b-button>
                                            <b-button @click="downloadBase64asImage(fileArray5[0], 'filename' , fileArrayFileType5[0])">
                                              D
                                            </b-button>
                                          </b-button-group>
                                        </div>
                                      </template>
                                    </b-row>
                                  </div>
                                </template>
                              </b-col>
                            </b-row>
                            <b-form-file
                              v-if="editMode"
                              v-model="fileInputMulti5"
                              accept=".jpg, .png"
                              placeholder="Choose a file or drop it here..."
                              drop-placeholder="Drop file here..."
                              @input="onFileSelect5('meta_image')"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                    </b-row>
                    <b-row>
                      <b-col>
                        <b-form-group>
                          <validation-provider
                            #default="{ errors }"
                            name="Meta Description"
                            rules=""
                          >
                            <label>Meta Description </label>
                            <b-form-input
                              v-model="actualForm.meta_description"
                              :plaintext="!editMode"
                              size="sm"
                              :state="errors.length > 0 ? false:null"
                              placeholder="Meta Description"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col />
                </b-row>
              </b-col>
            </b-row>
          </div>
          <!-- end group meta data-->

          <!-- group theme-->
          <div class="p-2 border-bottom">
            <b-row>
              <b-col cols="3">
                <h6 class="m-0">
                  <strong>Theme</strong>
                </h6>
              </b-col>
              <b-col>
                <b-row>
                  <b-col cols="8">
                    <b-row>
                      <b-col>
                        <b-form-group>
                          <validation-provider
                            #default="{ errors }"
                            name="Primary Color"
                            rules=""
                          >
                            <label>Primary Color </label>
                            <b-form-input
                              v-model="actualForm.primary_color"
                              :plaintext="!editMode"
                              size="sm"
                              :state="errors.length > 0 ? false:null"
                              placeholder="Primary color"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col>
                        <b-form-group>
                          <validation-provider
                            #default="{ errors }"
                            name="Secondary Color"
                            rules=""
                          >
                            <label>Secondary Color </label>
                            <b-form-input
                              v-model="actualForm.secondary_color"
                              :plaintext="!editMode"
                              size="sm"
                              :state="errors.length > 0 ? false:null"
                              placeholder="Secondary Color"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                    </b-row>
                    <b-row>
                      <b-col>
                        <b-form-group>
                          <validation-provider
                            #default="{ errors }"
                            name="Background Image"
                            rules=""
                          >
                            <label>Background Image</label>
                            <b-row>
                              <b-col>
                                <template>
                                  <div
                                    class="pt-1 px-2 bg-light mb-1"
                                  >
                                    <b-row v-if="fileArray6.length < 1 && !Array.isArray(fileArray6)">
                                      <b-col>
                                        <p class="mb-1 py-3 text-center">
                                          No Files Yet
                                        </p>
                                      </b-col>
                                    </b-row>
                                    <b-row>
                                      <b-img
                                        v-if="Array.isArray(fileArray6)"
                                        class="img-fluid mb-1"
                                        :src="processBase64String(actualForm.background_image)"
                                      />
                                      <template
                                        v-if="
                                          fileArrayFileType6[0] === 'jpeg' || fileArrayFileType6[0] === 'png' "
                                      >
                                        <b-img
                                          class="img-fluid mb-1"
                                          :src="processBase64String(actualForm.background_image)"
                                        />
                                        <div
                                          v-if="debugMode"
                                          class="d-none"
                                        >
                                          <b-button-group size="sm">
                                            <b-button @click="viewBase64asImage(fileArray6[0])">
                                              V
                                            </b-button>
                                            <b-button @click="downloadBase64asImage(fileArray6[0], 'filename' , fileArrayFileType6[0])">
                                              D
                                            </b-button>
                                          </b-button-group>
                                        </div>
                                      </template>
                                    </b-row>
                                  </div>
                                </template>
                              </b-col>
                            </b-row>
                            <b-form-file
                              v-if="editMode"
                              v-model="fileInputMulti6"
                              accept=".jpg, .png"
                              placeholder="Choose a file or drop it here..."
                              drop-placeholder="Drop file here..."
                              @input="onFileSelect6('background_image')"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col>
                        <b-form-group>
                          <validation-provider
                            #default="{ errors }"
                            name="Background Color"
                            rules=""
                          >
                            <label>Background Color </label>
                            <b-form-input
                              v-model="actualForm.background_color"
                              :plaintext="!editMode"
                              size="sm"
                              :state="errors.length > 0 ? false:null"
                              placeholder="Background color"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                    </b-row>
                  </b-col>
                  <b-col />
                </b-row>
              </b-col>
            </b-row>
          </div>
          <!-- end group theme-->

          <!-- group URLs-->
          <div class="p-2 border-bottom">
            <b-row>
              <b-col cols="3">
                <h6 class="m-0">
                  <strong>URLs</strong>
                </h6>
              </b-col>
              <b-col>
                <b-row>
                  <b-col cols="4">
                    <b-form-group>
                      <validation-provider
                        #default="{ errors }"
                        name="Contact URL"
                        rules=""
                      >
                        <label>Contact URL </label>
                        <b-form-input
                          v-model="actualForm.contact_url"
                          :plaintext="!editMode"
                          size="sm"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Contact URL"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-form-group>
                      <validation-provider
                        #default="{ errors }"
                        name="FAQ URL "
                        rules=""
                      >
                        <label>FAQ URL </label>
                        <b-form-input
                          v-model="actualForm.faq_url"
                          :plaintext="!editMode"
                          size="sm"
                          :state="errors.length > 0 ? false:null"
                          placeholder="FAQ URL"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <b-form-group>
                      <validation-provider
                        #default="{ errors }"
                        name="Contact URL"
                        rules=""
                      >
                        <label>Contact URL </label>
                        <b-form-input
                          v-model="actualForm.contact_url"
                          :plaintext="!editMode"
                          size="sm"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Contact URL"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="4">
                    <b-form-group>
                      <validation-provider
                        #default="{ errors }"
                        name="Site Support"
                        rules=""
                      >
                        <label>Site Support </label>
                        <b-form-input
                          v-model="actualForm.site_support"
                          :plaintext="!editMode"
                          size="sm"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Site Support"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-form-group>
                      <validation-provider
                        #default="{ errors }"
                        name="Privacy Term URL"
                        rules=""
                      >
                        <label>Privacy Term URL</label>
                        <b-form-input
                          v-model="actualForm.privacy_term_url"
                          :plaintext="!editMode"
                          size="sm"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Privacy Term URL"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col />
                </b-row>
              </b-col>
            </b-row>
          </div>
          <!-- end group URLs-->

          <!-- group Social-->
          <div class="p-2 border-bottom">
            <b-row>
              <b-col cols="3">
                <h6 class="m-0">
                  <strong>Social</strong>
                </h6>
              </b-col>
              <b-col>
                <b-row>
                  <b-col cols="8">
                    <b-row>
                      <b-col>
                        <b-form-group>
                          <validation-provider
                            #default="{ errors }"
                            name="Facebook"
                            rules=""
                          >
                            <label>Facebook </label>
                            <b-form-input
                              v-model="actualForm.facebook"
                              :plaintext="!editMode"
                              size="sm"
                              :state="errors.length > 0 ? false:null"
                              placeholder="Facebook"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                        <b-form-group>
                          <validation-provider
                            #default="{ errors }"
                            name="instagram"
                            rules=""
                          >
                            <label>Instagram </label>
                            <b-form-input
                              v-model="actualForm.instagram"
                              :plaintext="!editMode"
                              size="sm"
                              :state="errors.length > 0 ? false:null"
                              placeholder="Instagram"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col>
                        <b-form-group>
                          <validation-provider
                            #default="{ errors }"
                            name="linkedin"
                            rules=""
                          >
                            <label>Linkedin </label>
                            <b-form-input
                              v-model="actualForm.linkedin"
                              :plaintext="!editMode"
                              size="sm"
                              :state="errors.length > 0 ? false:null"
                              placeholder="LinkedIn"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                        <b-form-group>
                          <validation-provider
                            #default="{ errors }"
                            name="Twitter"
                            rules=""
                          >
                            <label>Twitter </label>
                            <b-form-input
                              v-model="actualForm.twitter"
                              :plaintext="!editMode"
                              size="sm"
                              :state="errors.length > 0 ? false:null"
                              placeholder="Twitter"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col />
                </b-row>
              </b-col>
            </b-row>
          </div>
          <!-- end group Social-->
          <!-- group API settings-->
          <div class="p-2 border-bottom">
            <b-row>
              <b-col cols="3">
                <h6 class="m-0">
                  <strong>API settings</strong>
                </h6>
              </b-col>
              <b-col>
                <b-row>
                  <b-col cols="8">
                    <b-row>
                      <b-col>
                        <b-form-group>
                          <validation-provider
                            #default="{ errors }"
                            name="Base URL Endpoint"
                            rules=""
                          >
                            <label>Base URL Endpoint </label>
                            <b-form-input
                              v-model="actualForm.base_url_endpoint"
                              :plaintext="!editMode"
                              size="sm"
                              :state="errors.length > 0 ? false:null"
                              placeholder="Base URL Endpoint"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                        <b-form-group>
                          <validation-provider
                            #default="{ errors }"
                            name="Callback URL"
                            rules=""
                          >
                            <label>Callback URL </label>
                            <b-form-input
                              v-model="actualForm.callback_url"
                              :plaintext="!editMode"
                              size="sm"
                              :state="errors.length > 0 ? false:null"
                              placeholder="Callback URL"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col />
                </b-row>
              </b-col>
            </b-row>
          </div>
          <!-- end group Social-->
          <!-- group footer-->
          <div class="p-2 border-bottom">
            <b-row>
              <b-col cols="3">
                <h6 class="m-0">
                  <strong>Footer</strong>
                </h6>
              </b-col>
              <b-col>
                <b-row>
                  <b-col cols="8">
                    <b-row>
                      <b-col>
                        <b-form-group>
                          <validation-provider
                            #default="{ errors }"
                            name="Powered By"
                            rules=""
                          >
                            <label>Powered By </label>
                            <b-form-input
                              v-model="actualForm.powered_bye"
                              :plaintext="!editMode"
                              size="sm"
                              :state="errors.length > 0 ? false:null"
                              placeholder="Powered By"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col />
                </b-row>
              </b-col>
            </b-row>
          </div>
          <!-- end group Social-->
        </div>
      </b-card>
    </validation-observer>
    <pre v-if="debugMode">{{ actualForm }}</pre>
    <!--    <pre v-if="debugMode">{{ processBase64String(actualForm.logo) }}</pre>-->
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import Config from '@/views/administration/options/formConfig'
import sampleImagesDummy from '@/views/administration/accounts/details/information/dummy.json'

export default {
  components: {
    ValidationObserver, ValidationProvider,
  },
  data() {
    return {
      actualForm: {
        record_status: false,
      },
      formMode: 'create',

      editMode: false,
      fileInputMulti: [],
      fileInputMulti2: [],
      fileInputMulti3: [],
      fileInputMulti4: [],
      fileInputMulti5: [],
      fileInputMulti6: [],

      fileArray: [],
      fileArray2: [],
      fileArray3: [],
      fileArray4: [],
      fileArray5: [],
      fileArray6: [],

      fileArrayFileType: [],
      fileArrayFileType2: [],
      fileArrayFileType3: [],
      fileArrayFileType4: [],
      fileArrayFileType5: [],
      fileArrayFileType6: [],

      pdfIconImage: require('@/assets/images/icons/filetype-icons/pdf.png'),
      csvIconImage: require('@/assets/images/icons/filetype-icons/csv.png'),
      docIconImage: require('@/assets/images/icons/filetype-icons/doc.png'),
      pptIconImage: require('@/assets/images/icons/filetype-icons/ppt.png'),
      xlsIconImage: require('@/assets/images/icons/filetype-icons/xls.png'),
      pngIconImage: require('@/assets/images/icons/filetype-icons/png.png'),
      fileIconImage: require('@/assets/images/icons/filetype-icons/file.png'),
    }
  },
  computed: {
    systemOptionsMeData() {
      const list = this.$store.getters['systemOption/systemOptionsMe']

      if (list) {
        return list
      }
      return []
    },
    parentPageNamedRoute() {
      return this.$route.matched[0].name
    },
    formTitle() {
      const title = this.formConfig.title[this.formMode]
      return title
    },
    formConfig() {
      if (Config) {
        return Config.formData()
      }
      return {}
    },
  },
  async mounted() {
    const breadcrumbUpdatePayload = [
      { title: 'System Options' },
    ]
    await this.$store.dispatch('breadcrumbs/setBreadcrumb', breadcrumbUpdatePayload)
    await this.$store.dispatch('systemOption/getSystemOptionsMe').then(res => {
      if (res.data.response_code === 2000) {
        this.actualForm = res.data.data
        // this.fileArray.push(res.data.data.logo)
        // this.fileArray2.push(res.data.data.logo_alt)
        // this.fileArray3.push(res.data.data.qr_code_logo)
        // this.fileArray4.push(res.data.data.favicon)
        // this.fileArray5.push(res.data.data.meta_image)
      }
    })
  },
  methods: {
    onSubmit() {
      const payload = {
        ...this.actualForm,
        payloadId: this.actualForm.id,
      }

      this.$store.dispatch('systemOption/updateSystemOptions', payload)
    },
    processBase64String(string) {
      const filetype = 'png'
      // alert(string)
      const base64str = string.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)
      console.log(base64str)

      if (base64str !== null) {
        return string
      }
      return `data:image/${filetype};base64,${string}`
    },
    getDummy() {
      const dummy = sampleImagesDummy
      this.fileArray = dummy.sampleFiles
      this.fileArray.forEach(item => {
        this.fileArrayFileType.push(this.getBase64FileType(item))
      })
    },
    getBase64(file) {
      return new Promise(((resolve, reject) => {
        const reader = new FileReader()
        let imgResult = ''
        reader.readAsDataURL(file)
        reader.onload = function () {
          // console.log('start')
          imgResult = reader.result
        }
        reader.onerror = function (error) {
          // console.log('error')
          reject(error)
        }
        reader.onloadend = function () {
          // console.log('end', imgResult)
          resolve(imgResult)
        }
      }))
    },
    onFileSelect(fieldName) {
      console.log('file selected')
      if (Array.isArray(this.fileInputMulti)) {
        this.fileInputMulti.forEach(item => {
          console.log('fileSelected', item)
          this.getBase64(item).then(res => {
            this.fileArray.push(res)
            this.actualForm[fieldName] = this.fileArray
            this.fileArrayFileType.push(this.getBase64FileType(res))
          })
        })
      } else {
        this.getBase64(this.fileInputMulti).then(res => {
          console.log('not array', res)
          this.fileArray.push(res)
          this.actualForm[fieldName] = res
          this.fileArrayFileType.push(this.getBase64FileType(res))
          console.log('fileArray', this.fileArray)
        })
      }
    },
    onFileSelect2(fieldName) {
      console.log('file2 selected')
      if (Array.isArray(this.fileInputMulti2)) {
        this.fileInputMulti2.forEach(item => {
          console.log('fileSelected', item)
          this.getBase64(item).then(res => {
            this.fileArray2.push(res)
            this.actualForm[fieldName] = this.fileArray2
            this.fileArrayFileType2.push(this.getBase64FileType(res))
          })
        })
      } else {
        this.getBase64(this.fileInputMulti2).then(res => {
          console.log('not array2', res)
          this.fileArray2.push(res)
          this.actualForm[fieldName] = res
          this.fileArrayFileType2.push(this.getBase64FileType(res))
          console.log('fileArray2', this.fileArray2)
        })
      }
    },

    onFileSelect3(fieldName) {
      console.log('file3 selected')
      if (Array.isArray(this.fileInputMulti3)) {
        this.fileInputMulti3.forEach(item => {
          console.log('fileSelected', item)
          this.getBase64(item).then(res => {
            this.fileArray3.push(res)
            this.actualForm[fieldName] = this.fileArray3
            this.fileArrayFileType3.push(this.getBase64FileType(res))
          })
        })
      } else {
        this.getBase64(this.fileInputMulti3).then(res => {
          console.log('not array3', res)
          this.fileArray3.push(res)
          this.actualForm[fieldName] = res
          this.fileArrayFileType3.push(this.getBase64FileType(res))
          console.log('fileArray3', this.fileArray3)
        })
      }
    },
    onFileSelect4(fieldName) {
      console.log('file4 selected')
      if (Array.isArray(this.fileInputMulti4)) {
        this.fileInputMulti4.forEach(item => {
          console.log('fileSelected', item)
          this.getBase64(item).then(res => {
            this.fileArray4.push(res)
            this.actualForm[fieldName] = this.fileArray4
            this.fileArrayFileType4.push(this.getBase64FileType(res))
          })
        })
      } else {
        this.getBase64(this.fileInputMulti4).then(res => {
          console.log('not array4', res)
          this.fileArray4.push(res)
          this.actualForm[fieldName] = res
          this.fileArrayFileType4.push(this.getBase64FileType(res))
          console.log('fileArray4', this.fileArray4)
        })
      }
    },
    onFileSelect5(fieldName) {
      console.log('file5 selected')
      if (Array.isArray(this.fileInputMulti5)) {
        this.fileInputMulti5.forEach(item => {
          console.log('fileSelected', item)
          this.getBase64(item).then(res => {
            this.fileArray5.push(res)
            this.actualForm[fieldName] = this.fileArray5
            this.fileArrayFileType5.push(this.getBase64FileType(res))
          })
        })
      } else {
        this.getBase64(this.fileInputMulti5).then(res => {
          console.log('not array5', res)
          this.fileArray5.push(res)
          this.actualForm[fieldName] = res
          this.fileArrayFileType5.push(this.getBase64FileType(res))
          console.log('fileArray5', this.fileArray5)
        })
      }
    },
    onFileSelect6(fieldName) {
      console.log('file6 selected')
      if (Array.isArray(this.fileInputMulti6)) {
        this.fileInputMulti6.forEach(item => {
          console.log('fileSelected', item)
          this.getBase64(item).then(res => {
            this.fileArray6.push(res)
            this.actualForm[fieldName] = this.fileArray6
            this.fileArrayFileType6.push(this.getBase64FileType(res))
          })
        })
      } else {
        this.getBase64(this.fileInputMulti6).then(res => {
          console.log('not array6', res)
          this.fileArray6.push(res)
          this.actualForm[fieldName] = res
          this.fileArrayFileType6.push(this.getBase64FileType(res))
          console.log('fileArray6', this.fileArray6)
        })
      }
    },
    getBase64FileType(base64string, mimeType = false) {
      const mime = base64string.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0]
      const extension = base64string.match(/[^:/]\w+(?=;|,)/)[0]
      // console.log(base64string)
      if (mimeType) {
        return mime
      }
      return extension
    },
    cancelForm() {
      this.editMode = false
    },
    makeId(length) {
      let result = ''
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
      const charactersLength = characters.length

      for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random()
            * charactersLength))
      }
      return result
    },
    viewBase64asImage(base64string) {
      console.log(base64string)
    },
    downloadBase64asImage(base64string, filename, extension) {
      const a = document.createElement('a') // Create <a>
      a.href = `${base64string}` // Image Base64 Goes here
      a.download = `${filename}.${extension}` // File name Here
      a.click() // Downloaded file
    },
    removeUploaded(index) {
      const { fileArray } = this
      const { fileArrayFileType } = this
      const { fileInputMulti } = this
      fileArray.splice(index, 1)
      fileArrayFileType.splice(index, 1)
      fileInputMulti.splice(index, 1)
    },
  },
}
</script>
